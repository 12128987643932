<template>
	<el-dialog :title="title" :close-on-click-modal="false" :visible.sync="visible" width="950px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="协调时间" class="item">
						<el-date-picker v-model="dataForm.coordinateTime" type="date"
							value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 720px;">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="协调双方人员" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="2" v-model="dataForm.coordinateMan"
							placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="协调内容" class="item">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3"
							v-model="dataForm.coordinateContent" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row style="margin-top: 15px;">
				<el-col :span="24">
					<el-form-item label="">
						<div class="addImgBox" v-for="(item, index) in dataForm.photoList">
							<el-image style="width: 100%; height: 100%" :src="item.photoFileUri" fit="cover"></el-image>
							<div class="delBox">
								<i class="el-icon-delete" @click="delImg(index)"></i>
							</div>
						</div>
						<el-upload class="addImgBox" ref="uploadImg" :action="action" :http-request="uploadImg"
							accept="image/jpeg,image/png,image/jpg">
							<el-image style="width: 100%; height: 100%" :src="addImg" fit="cover">
							</el-image>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import addImg from "@/assets/images/addImg.png"
import $common from "@/utils/common.js"
export default {
	name: "coordinate-add-or-update",
	data() {
		return {
			title: '',
			addImg: addImg,
			id: "",
			visible: true,
			action: "",
			form: {},
			subjectList: [],
			majorList: [],
			categoryList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				recNo: "",
				coordinateContent: "",
				coordinateTime: "",
				coordinateMan: "",
				registerMan: '',
				photoList: []
			},
			dataRule: {

			}
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init(id, situationType) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				recNo: "",
				coordinateContent: "",
				coordinateTime: "",
				coordinateMan: "",
				registerMan: '',
				photoList: []
			};
			this.dataForm.recNo = id ? id : 0;
			this.dataForm.registerMan = $common.getItem("userName");
			this.title = '新增协助记录';
			if (id) {
				this.getDetail();
				this.title = '修改协助记录';
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		getDetail() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/coordinaterecord/info/" + this.dataForm.recNo,
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataForm = data.body;
					if (!this.dataForm.registerMan) {
						this.dataForm.registerMan = $common.getItem("userName");
					}
				}
			});
		},
		//图片
		delImg(index) {
			this.dataForm.photoList.splice(index, 1);
		},
		uploadImg(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/93/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data;
				if (res.resultCode == 200) {
					this.dataForm.photoList.push({
						photoFilePath: res.body.fileSavePath,
						photoFileUri: res.body.fileUri
					});
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _url = "/business/coordinaterecord/save";
					if (this.dataForm.recNo && this.dataForm.recNo != 0) {
						_url = "/business/coordinaterecord/update";
					}
					this.$http({
						url: this.$store.state.httpUrl + _url,
						method: "post",
						data: this.$http.adornData(this.dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}


.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
